import api from '@/services/api';

export const getMyCourses = async () => {
  const result = await api.get(`/myCourses/`);
  return result.data;
};

export const getMyCourseById = async id => {
  const result = await api.get(`/myCourses/${id}`);
  return result.data;
};

export const getMyLesson = async id => {
  const result = await api.get(`/myCourses/lessons/${id}`);
  return result.data;
};

export const postMyLesson = async lesson => {
  const result = await api.post(`/myCourses/lessons/`, lesson);
  return result.data;
};

export const getExamQuestions = async userCourseId => {
  const result = await api.get(`/myCourses/${userCourseId}/exam`);
  return result.data;
};

export const completeExam = async userCourseId => {
  const result = await api.post(`/myCourses/${userCourseId}/complete`);
  return result.data;
};

/*
export const createLesson = async (payload) => {
  const result = await api.post(`/lessons`, payload)
  return result.data
}

export const updateLesson = async (payload) => {
  const result = await api.patch(`/lessons/${payload.id}`, payload)
  return result.data
}

export const deleteLesson = async (id) => {
  const result = await api.delete(`/lessons/${id}`)
  return result.data
}*/
