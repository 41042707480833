<template>
  <div v-loading="loading">
    <div class="bg-white rounded overflow-hidden">
      <div class="pt-4 pl-4 pr-4 md:pt-6 md:pl-6 md:pr-6 font-bold uppercase">
        {{ $t('models.exam') }}
      </div>
      <section
        class="mb-4 pt-4 pl-4 pr-4 md:pt-6 md:pl-6 md:pr-6"
        v-if="questions"
        name="questions"
      >
        <exam-component
          :questions="questions"
          @onCompleteExam="handleCompleteExam"
        />
      </section>

      <!-- <section class="p-4 md:p-6">
        <div v-if="questions">
          <exam-form
            :questions="questions"
            @onCompleteExam="handleCompleteExam"
          ></exam-form>
        </div>
      </section> -->
    </div>
  </div>
</template>

<script>
// import ExamForm from '../components/ExamForm';
import ExamComponent from '../components/Exam/ExamComponent';
import { getExamQuestions, completeExam } from '../api';
import {translate} from "@/i18n/Translator";
export default {
  components: {
    // ExamForm,
    ExamComponent
  },

  data() {
    return {
      courseId: null,
      questions: null,
      loading: false
    };
  },

  methods: {
    async getExam() {
      try {
        this.loading = true;
        this.questions = await getExamQuestions(this.courseId);
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async handleCompleteExam() {
      try {
        this.loading = true;
        await completeExam(this.courseId);
        this.$message({ message: this.$t('misc.completed'), type: 'success' });
        this.$router.push({ name: 'my-courses-index' });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$message({ message: this.$t('errors.general'), type: 'error' });
      }
    }
  },

  created() {
    this.courseId = this.$router.history.current.params.mcid;
    this.getExam();
  },


  mounted() {

  }
}
</script>

<style></style>
