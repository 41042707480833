<template>
  <el-button type="grey" @click="onAlternativeClick" :class="`${getCssClass()} clear-el-button`">

    {{ alternative.text.filter(t => t.lang === $i18n.locale)[0].value }}
  </el-button>
</template>

<script>
export default {
  components: { },
  props: {
    alternative: {
      type: Object,
      required: true
    },
    correctFeedback: {
      type: Boolean,
      required: true
    },
    selectedAlternative: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
    };
  },

  methods: {
    onAlternativeClick() {
      this.$emit('onClick', this.alternative);
    },


    getCssClass() {
      let css = '';
      if (this.isSelected) {
        if (this.correctFeedback) {
          if (this.alternative.isCorrect) css = 'active-correct';
          else css = 'active-incorrect';
        } else css = 'active';
      }
      return css;
    }
  },

  computed: {
    isSelected: function() {
      return !!(this.selectedAlternative &&
          this.selectedAlternative.id &&
          this.alternative &&
          this.alternative.id &&
          this.selectedAlternative.id === this.alternative.id);
    },
  },

  created() {
    this.selected = this.alternative.selected;
  }
};
</script>

<style scoped>
.clear-el-button {
  margin: 0;
  margin-top: 0.25rem;
}
.active {
  background-color: #eeeeee;
  color: white;
}
.active-correct {
  border-color: rgb(72, 187, 120);
  background-color: rgba(72, 187, 120, 0.2);
  /* background-color: #42e16a; */
  /* color: white; */
}
.active-incorrect {
  /* background-color: #e142b1; */
  /* border-color:#f56565; */
  background-color: #f56565;
  color: white;
  /* color: white; */
}

.el-button {
  margin-left: 0;
}

.el-button:hover {
  background-color: #eeeeee;
}

</style>
